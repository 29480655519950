body {
  margin: 0;
  font-family: "VT323", monospace;
  background-color: #2f2f2f;
  color: #f0f6ef;
}

a {
  color: inherit;
  text-decoration: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
